import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { toast } from "react-toastify"

import Layout from "components/layout"
import SEO from "components/seo"

import { isBrowser, getParameterByName } from "services/utils"

const Styles = styled.div``

export default function PaymentPage() {
  const [token, setToken] = useState("")
  const [stateSnap, setStateSnap] = useState(null)

  useEffect(() => {
    if (token) {
      if (stateSnap) {
        stateSnap.pay(token)
      } else if ("snap" in window) {
        const { snap } = window
        setStateSnap(snap)
        snap.pay(token)
      } else {
        toast.error("Checkout failed, please refresh your page and try again")
      }
    }
  }, [token])

  useEffect(() => {
    if (isBrowser() && !token) {
      const paramToken = getParameterByName("token")
      if (paramToken) {
        setToken(paramToken)
      } else {
        toast.error("Token invalid. Please contact the administrator")
      }
    }
  }, [stateSnap])

  useEffect(() => {
    if ("snap" in window) {
      const { snap } = window
      setStateSnap(snap)
    }
  }, [])

  return (
    <Layout>
      <SEO title="Initiating Payment" />
      <Styles className="layout">
        <h5>Initiating Payment...</h5>
      </Styles>
    </Layout>
  )
}
